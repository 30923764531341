import { Tag, Tooltip } from "antd";
import { truncate } from "lodash";
import React from "react";
import styles from "./TagContainer.module.css";
interface TagContainerProps {
  tags: string[];
  handleClose: (tag: string) => void;
  editable?: boolean;
  children?: React.ReactNode;
}
export const TagContainer = ({
  tags,
  handleClose,
  editable,
  children,
}: TagContainerProps) => {
  return (
    <div className={styles.container}>
      {tags.map<React.ReactNode>((tag) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag
            key={tag}
            closable={editable}
            className={styles.tag}
            onClose={() => handleClose(tag)}
            color="var(--primary-color)"
          >
            {isLongTag ? `${truncate(tag, { length: 20 })}` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {children}
    </div>
  );
};
