import { Button, Tooltip } from "antd";

import {
  IconDesk,
  IconDoor,
  IconFilter,
  IconHourglassHigh,
  IconHourglassLow,
  IconHourglassOff,
  IconPlus,
  IconUser,
  TablerIconsProps,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { SearchBar } from "../../../../components/SearchBar";
import { AnimatingListItem } from "../../../../components/common/AnimatingListItem/AnimatingListItem";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { EquipmentAssociation, EquipmentMinified } from "../../../../repo";
import { useEquipmentList } from "../../../../repo/equipment";
import { filterByTerm } from "../../../../utils/arrayUtils";
import { useNav } from "../../../../utils/reactUtils";
import { ControlsContainer } from "../../../common/ControlsContainer/ControlsContainer";
import IndicatingList from "../../../common/IndicatingList/IndicatingList";
import ListAndView from "../../../common/ListAndView/ListAndView";
import Scrollable from "../../../common/Scrollable/Scrollable";
import { EquipmentIcon } from "../../users/UserEditView/UserEditView";
import {
  isFilterActive,
  useEquipmentFilterStore,
} from "../context/equipmentFilterStore";
import styles from "./EquipmentPage.module.css";
import { EquipmentFilterDialog } from "./components/EquipmentFilterDialog/EquipmentFilterDialog";
type Props = {};

export default function EquipmentPage({}: Props) {
  return <ListAndView list={<EquipmentListSection />} content={<Outlet />} />;
}

const EquipmentListSection = () => {
  let [searchParams, _] = useSearchParams();
  const nav = useNav();
  const { setEquipment, filteredEquipment } = useEquipmentFilterStore();
  const { data, isError, isLoading, refetch } = useEquipmentList();
  useEffect(() => {
    if (data) {
      setEquipment(data);
    }
  }, [data, setEquipment]);

  let equipmentList = filterByTerm<EquipmentMinified>(
    filteredEquipment,
    searchParams.get("searchTerm")
  );

  return (
    <LoadingBoundry
      loading={isLoading}
      error={isError}
      description="Could not load equipment"
      onAction={refetch}
    >
      <ControlsContainer>
        <SearchBar path={"/equipment"} />
        <FilterEquipmentButton />
        <Tooltip title={"Add new equipment"} placement="bottom">
          <Button
            type="primary"
            size="large"
            onClick={() => nav("/equipment/new" + window.location.search)}
            icon={<IconPlus />}
          />
        </Tooltip>
      </ControlsContainer>
      <Scrollable>
        <IndicatingList<EquipmentMinified>
          emptyTitle="No equipment found"
          data={equipmentList}
          render={(equipment) => (
            <EquipmentListItem
              key={equipment.id}
              equipment={equipment}
              onClick={() => {
                nav(`/equipment/${equipment.id}${window.location.search}`);
              }}
            />
          )}
        />
      </Scrollable>
      <EquipmentFilterDialog />
    </LoadingBoundry>
  );
};
const FilterEquipmentButton = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterActive = isFilterActive();
  const toggleFilter = () => {
    searchParams.set("action", "equipmentFilter");
    setSearchParams(searchParams);
  };
  return (
    <Tooltip title={"Filter equipment"} placement="bottom">
      <Button
        type="primary"
        size="large"
        onClick={toggleFilter}
        icon={
          <IconFilter
            color={filterActive ? `var(--supplementary-color)` : undefined}
          />
        }
      />
    </Tooltip>
  );
};
export const EquipmentListItem = ({
  equipment,
  size = "large",
  onClick,
}: {
  equipment: EquipmentMinified;
  size?: "large" | "small";
  onClick?: (eq: EquipmentMinified) => void;
}) => {
  const { equipmentId } = useParams();
  const selected = equipmentId === equipment.id;
  const [hovered, setHovered] = useState(false);
  return (
    <AnimatingListItem
      active={selected}
      className={`${styles.equipmentListItemContainer} ${styles[size]}`}
      onClick={() => onClick?.(equipment)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <IconWithBackground
        icon={<EquipmentIcon type={equipment.type} />}
        backgroundColor={
          selected || hovered ? "var(--supplementary-color)" : undefined
        }
      />

      <div className={styles.equipmentListItemContent}>
        <span className={styles.equipmentListItemTitle}>{equipment.name}</span>
        {equipment.identifier && (
          <span className={styles.equipmentListItemIdentifier}>
            {equipment.identifier}
          </span>
        )}
      </div>

      <div className={styles.equipmentListClock}>
        <EquipmentAssociationIcon type={equipment.association?.type} />
        <EquipmentExpirationIndicator expiration={equipment.expiration} />
      </div>
    </AnimatingListItem>
  );
};

export const EquipmentExpirationIndicator = ({
  expiration,
}: {
  expiration?: string | Date;
}) => {
  const expirationDay = expiration && dayjs(expiration);
  if (!expirationDay || !expirationDay.isValid()) {
    return <></>;
  }
  if (expirationDay.isBefore(dayjs())) {
    return (
      <span title="Expired">
        <IconHourglassOff color="red" />
      </span>
    );
  }
  if (expirationDay.isBefore(dayjs().add(1, "M"))) {
    return (
      <span title="About to expire">
        <IconHourglassLow />
      </span>
    );
  }
  return (
    <span title="Has expiration">
      <IconHourglassHigh />
    </span>
  );
};
interface EquipmentAssociationIconProps extends TablerIconsProps {
  type?: EquipmentAssociation["type"];
}
export const EquipmentAssociationIcon = ({
  type,
  ...props
}: EquipmentAssociationIconProps) => {
  if (type) {
    switch (type) {
      case "USER":
        return <IconUser {...props} />;
      case "DESK":
        return <IconDesk {...props} />;
      case "ROOM":
        return <IconDoor {...props} />;
      default:
        return <></>;
    }
  }
  return <></>;
};
