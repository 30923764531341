import React from "react";
import styles from "./ControlsContainer.module.css";

export const ControlsContainer = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  return (
    <div {...props} className={styles.container}/>
  );
};
