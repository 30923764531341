import { Checkbox } from "antd";
import { useSearchParams } from "react-router-dom";
import { ComponentWithLabel } from "../../../../../../components/common/CustomInputText/CustomInputText";
import { ModalDialog } from "../../../../../../components/common/ModalDialog/ModalDialog";
import BorderDivider from "../../../../../common/BorderDivider/ListDivider";
import { TagContainer } from "../../../../../common/TagContainer/TagContainer";
import { useEquipmentFilterStore } from "../../../context/equipmentFilterStore";
import { EquipmentTypeSelector } from "../../../EquipmentCreateView/EquipmentCreateView";
import styles from "./EquipmentFilterDialog.module.css";
export const EquipmentFilterDialog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const close = () => {
    searchParams.delete("action");
    setSearchParams(searchParams);
  };
  const filter = useEquipmentFilterStore.use.filter();
  const setFilter = useEquipmentFilterStore.use.setFilter();
  const open = searchParams.get("action") === "equipmentFilter";
  return (
    <ModalDialog open={open} title="Equipment filter" onClose={close}>
      <div className={styles.container}>
        <div>
          <ComponentWithLabel label="Free">
            <Checkbox
              checked={filter.free}
              onChange={(e) => setFilter({ ...filter, free: e.target.checked })}
            />
          </ComponentWithLabel>
          <ComponentWithLabel label="Expired">
            <Checkbox
              checked={filter.expired}
              onChange={(e) =>
                setFilter({ ...filter, expired: e.target.checked })
              }
            />
          </ComponentWithLabel>
        </div>
        <BorderDivider horizontal />
        <div className={styles.typeContainer}>
          <EquipmentTypeSelector
            value={null}
            onSelect={(s) => {
              setFilter({
                ...filter,
                types: Array.from(new Set([...filter.types, s])),
              });
            }}
            filter={(t) => {
              return !filter.types.includes(t.toUpperCase());
            }}
          />
          <TagContainer
            editable
            tags={filter.types}
            handleClose={(t) => {
              setFilter({
                ...filter,
                types: filter.types.filter((ty) => ty !== t),
              });
            }}
          ></TagContainer>
        </div>
      </div>
    </ModalDialog>
  );
};
