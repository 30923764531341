import { IconChevronRight, IconTrash } from "@tabler/icons-react";
import { Button, DatePicker, Input } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithIcon from "../../../../components/common/ButtonWithIcon";
import { ConfirmationDialog } from "../../../../components/common/ConfirmationDialog/ConfirmationDialog";
import {
  ComponentWithError,
  ComponentWithLabel,
  CustomInputText,
} from "../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { EquipmentResponse } from "../../../../repo";
import {
  useDeleteEquipment,
  useEquipment,
  useUpdateEquipment,
} from "../../../../repo/equipment";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import { EquipmentTypeSelector } from "../EquipmentCreateView/EquipmentCreateView";
import styles from "./EquipmentEditPage.module.css";
type Props = {};

export default function EquipmentEditPage({}: Props) {
  const { equipmentId } = useParams();
  const { handleSubmit, control, reset } = useForm<EquipmentResponse>({
    defaultValues: {
      expiration: null as any,
      description: null as any,
      identifier: null as any,
    },
  });
  const nav = useNavigate();
  const {
    data: equipment,
    error: equipmentFetchError,
    isLoading: isEquipmentFetchLoading,
    refetch,
  } = useEquipment(equipmentId);
  useEffect(() => {
    if (equipment) {
      reset({
        ...equipment,
        expiration: equipment.expiration || null,
        description: equipment.description || (null as any),
        identifier: equipment.identifier || (null as any),
      });
    }
  }, [equipment, reset]);
  const {
    mutate: update,
    error: equipmentUpdateError,
    isPending: isEquipmentUpdateLoading,
    reset: resetUpdate,
  } = useUpdateEquipment();
  const {
    mutate: deleteMutation,
    error: equipmentDeleteError,
    isPending: isEquipmentDeleteLoading,
    reset: resetDelete,
  } = useDeleteEquipment();
  const updateEquipment = (data: EquipmentResponse) => {
    update({
      equipmentId: data.id,
      data: { ...data, type: data.type!.toUpperCase() as any },
    });
  };
  const deleteEquipment = () => {
    if (equipment) {
      deleteMutation(equipment, {
        onSuccess(data, variables, context) {
          nav("/equipment", { replace: true });
        },
      });
    }
  };
  const navToAssociation = () => {
    if (!equipment?.association) {
      return;
    }
    if (equipment.association.type === "DESK") {
      nav(
        `/maps/${equipment.association.metadata.mapId}/desks/${equipment?.association.id}`
      );
    } else if (equipment.association.type === "ROOM") {
      nav(
        `/maps/${equipment.association.metadata.mapId}/rooms/${equipment?.association.id}`
      );
    } else {
      nav(`/people/${equipment?.association.id}`);
    }
  };
  const errorO = (function () {
    const isError =
      equipmentFetchError || equipmentDeleteError || equipmentUpdateError;

    if (!isError) {
      return { isError };
    }
    const resStatus = equipmentFetchError?.response?.status;
    if (equipmentFetchError) {
      if (resStatus === 404) {
        return {
          isError,
          description: "Equipment not found",
          action: "Go back",
          onAction: () => nav(-1),
        };
      }
    }
    const description = equipmentFetchError
      ? "Could not fetch equipment"
      : equipmentUpdateError
      ? "Could not update equipment"
      : "Failed to delete equipment";
    const onAction = equipmentDeleteError
      ? resetDelete
      : equipmentUpdateError
      ? resetUpdate
      : refetch;
    return { description, onAction, isError };
  })();
  return (
    <CenteredFloatingContainer
      header={"Edit equipment"}
      backRoute={"/equipment"}
    >
      <LoadingBoundry
        loading={
          isEquipmentFetchLoading ||
          isEquipmentDeleteLoading ||
          isEquipmentUpdateLoading
        }
        error={errorO.isError}
        description={errorO.description}
        action={errorO.action}
        onAction={errorO.onAction}
      >
        <EquipmentForm
          onSubmit={handleSubmit(updateEquipment)}
          control={control}
        >
          <EquipmentButtonContainer>
            <DeleteButton onDelete={deleteEquipment} />
            <Button size="middle" htmlType="submit" type="primary">
              Update
            </Button>
          </EquipmentButtonContainer>

          {equipment?.association && (
            <div className={styles.associationContainer}>
              <ButtonWithIcon
                icon={<IconChevronRight />}
                onClick={navToAssociation}
                variant="link"
              >
                Go to association
              </ButtonWithIcon>
            </div>
          )}
        </EquipmentForm>
      </LoadingBoundry>
    </CenteredFloatingContainer>
  );
}

const DeleteButton = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <ConfirmationDialog
      primaryButtonProps={{
        type: "default",
        danger: true,
        icon: <IconTrash />,
      }}
      confirmText="Delete"
      type="danger"
      heading="Are you sure you want to delete this equipment?"
      onConfirm={onDelete}
    >
      <Button size="middle" danger>
        Delete
      </Button>
    </ConfirmationDialog>
  );
};
interface EquipmentFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  control: any;
}
export const EquipmentForm = ({ control, ...props }: EquipmentFormProps) => {
  return (
    <form {...props} className={styles.equipmentForm}>
      <EquipmentEditBody control={control} />
      {props.children}
    </form>
  );
};
export const EquipmentButtonContainer = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  return (
    <div {...props} className={styles.buttonContainer}>
      {props.children}
    </div>
  );
};
export const EquipmentEditBody = ({ control }: { control: any }) => {
  return (
    <div className={styles.formBody}>
      <div className={styles.formRow}>
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState }) => {
            return (
              <EquipmentTypeSelector
                required
                error={fieldState.error?.message}
                onSelect={field.onChange}
                value={field.value}
              />
            );
          }}
          rules={{ required: "Type is required" }}
        />
        <Controller
          render={({ field, fieldState }) => {
            return (
              <CustomInputText
                {...field}
                error={fieldState.error?.message}
                label="Name"
                placeholder="WhoDesk computer"
                autoComplete="equipmentName"
                required={true}
              />
            );
          }}
          rules={{
            required: "Name is required",
            maxLength: { value: 250, message: "Value too long" },
          }}
          name={`name`}
          control={control}
        />
      </div>
      <div className={styles.formRow}>
        <Controller
          render={({ field, fieldState }) => {
            return (
              <CustomInputText
                {...field}
                error={fieldState.error?.message}
                label="Identifier"
                placeholder="ID-XXXX"
                autoComplete="equipmentIdentifier"
              />
            );
          }}
          rules={{
            maxLength: { value: 250, message: "Value too long" },
          }}
          name={`identifier`}
          control={control}
        />
      </div>
      <div className={styles.formRow}>
        <Controller
          control={control}
          name={`expiration`}
          render={({ field, fieldState }) => {
            return (
              <ComponentWithLabel label="Expiration date:">
                <ComponentWithError error={fieldState.error?.message}>
                  <DatePicker
                    size="large"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date, dateString) => {
                      field.onChange(dateString);
                    }}
                  />
                </ComponentWithError>
              </ComponentWithLabel>
            );
          }}
        />
        <div>
          <Controller
            render={({ field, fieldState }) => {
              return (
                <ComponentWithLabel label="Description:">
                  <Input.TextArea
                    {...field}
                    style={{ resize: "none" }}
                    count={{ show: true, max: 500 }}
                    status={fieldState.error && "error"}
                    placeholder="Equipment description"
                    size="large"
                  />
                  <ComponentWithError error={fieldState.error?.message} />
                </ComponentWithLabel>
              );
            }}
            name={`description`}
            control={control}
            rules={{
              maxLength: { value: 500, message: "Description too long" },
            }}
          />
        </div>
      </div>
    </div>
  );
};
