import dayjs from "dayjs";
import { create } from "zustand";
import {
    createJSONStorage,
    persist,
    subscribeWithSelector,
} from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { EquipmentMinified } from "../../../../repo";
import { createSelectors } from "../../../../utils/reactUtils";
type EquipmentFilter = {
  types: string[];
  free?: boolean;
  term?: string;
  expired?: boolean;
};
type State = {
  equipment: EquipmentMinified[];
  filteredEquipment: EquipmentMinified[];
  filter: EquipmentFilter;
};

type Actions = {
  reset: () => void;
  setEquipment: (equipment: EquipmentMinified[]) => void;
  setFilter: (filter: EquipmentFilter) => void;
};

const initialState: State = {
  equipment: [],
  filteredEquipment: [],
  filter: {
    types: [],
  },
};

const useEquipmentFilterStoreInner = create<State & Actions>()(
  subscribeWithSelector(
    persist(
      immer<State & Actions>((set) => ({
        ...initialState,
        reset: () => {
          set(initialState);
        },
        setEquipment(equipment) {
          set((state) => {
            state.equipment = equipment;
            state.filteredEquipment = filterEquipment(
              state.equipment,
              state.filter
            );
          });
        },
        setFilter(filter) {
          set((state) => {
            state.filter = filter;
            state.filteredEquipment = filterEquipment(
              state.equipment,
              state.filter
            );
          });
        },
      })),
      {
        name: "equipmentFilterStore-storage",
        storage: createJSONStorage(() => localStorage),
        version: 1,
      }
    )
  )
);

export const useEquipmentFilterStore = createSelectors(
  useEquipmentFilterStoreInner
);

export const isFilterActive = () => {
  const filter = useEquipmentFilterStore.use.filter();
  return filter.expired || filter.free || filter.types.length > 0;
};

useEquipmentFilterStore.subscribe(
  (state) => state,
  (state) => {}
);
function filterEquipment(
  equipment: State["equipment"],
  filter: State["filter"]
) {
  const filteredEquipment = equipment
    .filter((e) => {
      if (filter.free === true) {
        return e.association === undefined;
      }
      return true;
    })
    .filter((e) => {
      if (filter.expired !== undefined && filter.expired) {
        return dayjs(e.expiration).isBefore(dayjs());
      }
      return true;
    })
    .filter((e) => {
      if (filter.types.length > 0) {
        return filter.types.includes(e.type);
      }
      return true;
    });
  return filteredEquipment;
}
