import { Button, Flex, Select } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ComponentWithError,
  ComponentWithLabel,
} from "../../../../components/common/CustomInputText/CustomInputText";
import LoadingBoundry from "../../../../components/common/LoadingBoundry/LoadingBoundry";
import { EquipmentMinified, EquipmentType } from "../../../../repo";
import { useCreateEquipment } from "../../../../repo/equipment";
import CenteredFloatingContainer from "../../../common/CenteredFloatingContainer/CenteredFloatingContainer";
import { EquipmentIcon } from "../../users/UserEditView/UserEditView";
import {
  EquipmentButtonContainer,
  EquipmentForm,
} from "../EquipmentEditPage/EquipmentEditPage";
import styles from "./EquipmentCreateView.module.css";
type Props = {};

export default function EquipmentCreateView({}: Props) {
  const { handleSubmit, control } = useForm<EquipmentMinified>();
  const nav = useNavigate();
  const {
    mutate: create,
    isPending,
    isError,
    reset,
  } = useCreateEquipment((data) => {
    nav(`/equipment/${data.id}`, { replace: true });
  });
  const createEquipment = (data: EquipmentMinified) => {
    create(data);
  };
  return (
    <LoadingBoundry
      loading={isPending}
      error={isError}
      description={"Could not create equipment"}
      onAction={reset}
    >
      <CenteredFloatingContainer
        header={"Create equipment"}
        backRoute={"/equipment"}
      >
        <EquipmentForm
          onSubmit={handleSubmit(createEquipment)}
          control={control}
        >
          <EquipmentButtonContainer>
            <Button size="middle" danger onClick={() => nav(-1)}>
              Cancel
            </Button>
            <Button size="middle" htmlType="submit" type="primary">
              Save
            </Button>
          </EquipmentButtonContainer>
        </EquipmentForm>
      </CenteredFloatingContainer>
    </LoadingBoundry>
  );
}

export const EquipmentTypeSelector = (props: {
  value?: EquipmentType | null;
  onSelect: (selection: EquipmentType) => void;
  error?: string;
  required?: boolean;
  filter?: (type: string) => boolean;
}) => {
  const types = [
    "Other",
    "Computer",
    "Phone",
    "Accessory",
    "Software",
    "Monitor",
    "Speaker",
    "Camera",
    "TV",
  ]
    .filter((t) => {
      if (props.filter) {
        return props.filter(t);
      }
      return true;
    })
    .sort();
  return (
    <ComponentWithError error={props.error}>
      <ComponentWithLabel label="Type:" required={props.required}>
        <Select
          size="large"
          optionLabelProp="label"
          status={props.error ? "error" : ""}
          value={props.value}
          className={styles.equipmentTypeSelector}
          placement="bottomLeft"
          placeholder="Select type"
          onSelect={props.onSelect}
        >
          {types.map((data) => {
            const label = (
              <Flex align="center">
                <EquipmentIcon type={data.toLowerCase() as EquipmentType} />
                &nbsp;
                <span>{data}</span>
              </Flex>
            );
            return (
              <Select.Option
                key={data.toLowerCase()}
                value={data.toUpperCase()}
                label={label}
              >
                {data}
              </Select.Option>
            );
          })}
        </Select>
      </ComponentWithLabel>
    </ComponentWithError>
  );
};
